export default {
    path: "wifi",
    name: "card-plugin-wifi",
    redirect: "/card/plugin/wifi/manage",
    component:()=>import("@/view/card/plugin/wifi"),
    children: [
        {
            path: "hollow",
            name: "card-plugin-wifi-hollow",
            component: () => import("@/view/card/plugin/wifi/hollow"),
            meta: {
                title: "空码"
            }
        },
        {
            path: "manage",
            name: "card-plugin-wifi-manage",
            component: () => import("@/view/card/plugin/wifi/manage"),
            meta: {
                title: "wifi码"
            },
        },
        {
            path: "tpl",
            name: "card-plugin-wifi-tpl",
            component: () => import("@/view/card/plugin/wifi/tpl"),
            meta: {
                title: "海报模版"
            },
        },
        {
            path: "tpl-edit",
            name: "card-plugin-wifi-tpl-edit",
            component: () => import("@/view/card/plugin/wifi/tpl-edit"),
            meta: {
                title: "海报模版"
            },
        },
        {
            path: "conf",
            name: "card-plugin-wifi-conf",
            component: () => import("@/view/card/plugin/wifi/conf"),
            meta: {
                title: "基础配置"
            },
        },
    ],
}