export default {
    path: "order",
    component: () => import("@/view/shop/order/index"),
    redirect: "/shop/order/manage",
    name: "order",
    meta: {
        title: "订单"
    },
    children: [
        {
            path: "manage",
            name: "shopOrderManage",
            component: () => import("@/view/shop/order/manage"),
            meta: {
                title: "订单管理"
            }
        }
    ]
}