import Vue from "vue";

import config from "@/config";

const system = {};
const base = config.api_base + "/system"

system.wechatOfficial = (params)=>Vue.axios.post( base + "/wechat-official",params);
system.wechatOfficialEdit = (params)=>Vue.axios.post( base + "/wechat-official-edit",params);

system.wechatOfficialSubstitute = (params)=>Vue.axios.post( base + "/wechat-official-substitute",params);
system.wechatOfficialSubstituteEdit = (params)=>Vue.axios.post( base + "/wechat-official-substitute-edit",params);

system.wechatMiniUploadCode = (params)=>Vue.axios.post( base + "/wechat-mini-upload-code",params);
system.wechatMini = (params)=>Vue.axios.post( base + "/wechat-mini",params);
system.wechatMiniEdit = (params)=>Vue.axios.post( base + "/wechat-mini-edit",params);

system.wechatPayment = (params)=>Vue.axios.post( base + "/wechat-payment",params);
system.wechatPaymentEdit = (params)=>Vue.axios.post( base + "/wechat-payment-edit",params);

system.aliPayment = (params)=>Vue.axios.post( base + "/ali-payment",params);
system.aliPaymentEdit = (params)=>Vue.axios.post( base + "/ali-payment-edit",params);

system.sms = (params)=>Vue.axios.post( base + "/sms",params);
system.smsEdit = (params)=>Vue.axios.post( base + "/sms-edit",params);

system.setting = (params)=>Vue.axios.post( base + "/setting",params);
system.settingEdit = (params)=>Vue.axios.post( base + "/setting-edit",params);

system.adPopup = (params)=>Vue.axios.post( base + "/ad-popup",params);
system.adPopupEdit = (params)=>Vue.axios.post( base + "/ad-popup-edit",params);

export default system