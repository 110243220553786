export default {
    path: "wholesaler",
    name: "card-plugin-wholesaler",
    redirect: "/card/plugin/wholesaler/user",
    component: () => import("@/view/card/plugin/wholesaler"),
    meta: {title: "批发商管理"},
    children: [
        {
            path: "conf",
            name: "card-plugin-wholesaler-conf",
            component: () => import("@/view/card/plugin/wholesaler/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "mode-rule",
            name: "card-plugin-wholesaler-mode-rule",
            component: () => import("@/view/card/plugin/wholesaler/mode-rule"),
            meta: {
                title: "模式规则"
            }
        },
        {
            path: "user",
            name: "card-plugin-wholesaler-mode-user",
            component: () => import("@/view/card/plugin/wholesaler/user"),
            meta: {
                title: "批发商管理"
            }
        },
        {
            path: "order",
            name: "card-plugin-wholesaler-mode-order",
            component: () => import("@/view/card/plugin/wholesaler/order"),
            meta: {
                title: "激活卡订单"
            }
        },
    ],
}