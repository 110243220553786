export default [
    {
        path: "/founder",
        component: () => import("@/view/founder/index"),
        redirect: "/founder/uni",
        name: "founder",
        meta: {
            title: "站点管理"
        },
        children: [
            {
                path: "uni",
                name: "founder-uni",
                component: () => import("@/view/founder/uni"),
                meta: {
                    title: "平台管理"
                }
            },
            {
                path: "account",
                name: "founder-account",
                component: () => import("@/view/founder/account"),
                meta: {
                    title: "账号管理"
                }
            },
            {
                path: "site",
                name: "founder-site",
                component: () => import("@/view/founder/site"),
                meta: {
                    title: "站点设置"
                }
            },
            {
                path: "sys",
                name: "founder-sys",
                component: () => import("@/view/founder/sys"),
                meta: {
                    title: "系统更新"
                }
            },
        ]
    }
]