import store from "@/store";
import api from "@/api";
import router from "@/router";

export function routerSafe(router){
    router.beforeEach(async (to, from, next) =>{
        if(to?.meta?.title)document.title = to.meta.title;
        if(!to.name || !["account-login"].includes(to.name)) {
            if(! await isLogin())return next({
                name:"account-login",
                query:{redirect:to.fullPath}
            })
        }
        if (["account-login"].includes(to.name) && await isLogin()){
            return next({
                name:"account-info",
            })
        }
        if (from.fullPath === "/" && to.fullPath === "/account/info"){
            return next({
                path:store.state.vuex_home_page
            })
        }
        if (to.path.includes("/founder")){
            vuex("vuex_active_uniacid",0)
        }
        next();
    })
}

export async function isLogin(){
    if(!store.state.vuex_token.data)return false;
    if ((store.state.vuex_token.time + 1000*60*60*3) < new Date().getTime()){
        setToken("");
        return await rememberLogin();
    }else {
        if (store.state.vuex_home_page === ""){
            let res = await api.account.info();
            setAccountInfo(res)
        }
    }
    return true;
}

export function rememberLogin(){
    return new Promise((resolve => {
        let r = store.state.vuex_remember;
        if (!r.u || !r.p)return resolve(false);
        login({username:r.u,password:r.p}).then(()=>{
            resolve(true)
        }).catch(()=>{
            setRemember({username:"",password:""})
            resolve(false);
        })
    }))
}

export function login({username,password}){
    return new Promise((resolve, reject) => {
        api.account.auth({username:username,password:password}).then(res=>{
            setAccountInfo(res)
            setToken(res.token);
            resolve(res)
        }).catch(err=>{
            reject(err);
        })
    })
}

export function formatDate(value){
    if (!value) return "";
    let date = new Date(value);
    if (date.getTime() < 0) return "";
    const YY = date.getFullYear() + '-';
    const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
}

export function toMedia(value){
    if (!value) return '';
    if (value.includes("http")) return value;
    return store.getters.activeUniacidAttachmentUrl + value;
}

export function vuex(name,value){
    store.commit('$uStore', {
        name,value
    })
}

export function setAccountInfo({home_page,info,uni,menu}){
    vuex("vuex_home_page",home_page);
    vuex("vuex_account",info);
    vuex("vuex_uni",uni);
    vuex("vuex_menu",menu);
    if(!info.is_founder){
        vuex("vuex_active_uniacid",uni.id);
    }
    if (store.state.vuex_active_uniacid){
        api.card.plugin.manage.enable().then(res=>{
            vuex("vuex_plugin",res);
        })
    }
}

export function setToken(token){
    vuex("vuex_token",{
        data: token ? token : "",
        time: token ? new Date().getTime() : 0,
    })
}
export function setRemember({username,password}){
    vuex("vuex_remember",{
        u:username,
        p:password,
    })
}

export function accountLogout(){
    setToken("");
    setRemember({username:"",password:""});
    router.push({name:"account-login"})
}

export function EnumValue2Label(val, raw) {
    const keys = Object.keys(raw);
    let out = "未知";
    keys.forEach((item) => {
        if (raw[item].value === val) {
            out = raw[item].label;
        }
    });
    return out;
}
