import config from "@/config";
import Vue from "vue";

const base = config.api_base + "/card/plugin/wifi";


export default {
    codeEdit: (params) => Vue.axios.post(base + "/code-edit", params),
    codeSearch: (params) => Vue.axios.post(base + "/code-search", params),
    connectLogSearch: (params) => Vue.axios.post(base + "/connect-log-search", params),
    conf: (params) => Vue.axios.post(base + "/conf", params),
    confEdit: (params) => Vue.axios.post(base + "/conf-edit", params),
    hollowGenerate: (params) => Vue.axios.post(base + "/hollow-generate", params),
    hollowExport: (params) => Vue.axios.post(base + "/hollow-export", params),
    hollowClear: (params) => Vue.axios.post(base + "/hollow-clear", params),
    tplAll: (params) => Vue.axios.post(base + "/tpl-all", params),
    tplEdit: (params) => Vue.axios.post(base + "/tpl-edit", params),
    tplOne: (params) => Vue.axios.post(base + "/tpl-one", params),
    tplDel: (params) => Vue.axios.post(base + "/tpl-del", params),
    tplClear: (params) => Vue.axios.post(base + "/tpl-clear", params),
}