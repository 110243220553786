export default {
    path: "system",
    component: () => import("@/view/shop/system/index"),
    redirect: "/shop/system/info",
    name: "shopSystem",
    meta: {
        title: "系统"
    },
    children: [
        {
            path: "info",
            name: "shopSystemInfo",
            component: () => import("@/view/shop/system/info"),
            meta: {
                title: "系统配置"
            }
        },

        {
            path: "poster-v2",
            name: "shop-system-rule-poster-v2",
            component: () => import("@/view/shop/system/poster-v2"),
            meta: {
                title: "海报管理"
            },
        },
        {
            path: "poster-v2-edit",
            name: "shop-system-rule-poster-v2-edit",
            component: () => import("@/view/shop/system/poster-v2-edit"),
            meta: {
                title: "海报编辑"
            },
        },
    ]
}