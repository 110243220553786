export default {
    path: "cdkey",
    name: "card-plugin-cdkey",
    redirect: "/card/plugin/cdkey/user",
    component:()=>import("@/view/card/plugin/cdkey"),
    children: [
        {
            path: "user",
            name: "card-plugin-cdkey-user",
            component: () => import("@/view/card/plugin/cdkey/user"),
            meta: {
                title: "用户管理"
            }
        },
        {
            path: "empty",
            name: "card-plugin-cdkey-empty",
            component: () => import("@/view/card/plugin/cdkey/empty.vue"),
            meta: {
                title: "空码管理"
            },
        },
        {
            path: "list",
            name: "card-plugin-cdkey-list",
            component: () => import("@/view/card/plugin/cdkey/list.vue"),
            meta: {
                title: "激活码"
            },
        },
    ],
}