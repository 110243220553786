import Vue from "vue";

import config from "@/config";
// import {getUniacid} from "@/api";

const attach = {};
const base = config.api_base + "/attach"

attach.wxPayPemAction = base+"/wx-pay-pem";
attach.wxPayPemLoad = (params)=>Vue.axios.post(base + "/wx-pay-pem-load", params);

attach.wxSubstitutePayPemAction = base+"/wx-substitute-pay-pem";
attach.wxSubstitutePayPemLoad = (params)=>Vue.axios.post(base + "/wx-substitute-pay-pem-load", params);

attach.aliPayPemAction = base+"/ali-pay-pem";
attach.aliPayPemLoad = (params)=>Vue.axios.post(base + "/ali-pay-pem-load", params);

attach.uploadAction = base+"/upload";
attach.videoTranscode = (params)=>Vue.axios.post(base + "/video-transcode", params)
attach.page = (params)=>Vue.axios.post(base + "/page", params)
attach.renameTitle = (params)=>Vue.axios.post(base + "/rename-title", params)
attach.del = (params)=>Vue.axios.post(base + "/del", params)

// attach.transcode = (params) => Vue.axios.post(base + "/video-transcode", params)

attach.group = {
    del: (params) => Vue.axios.post(base + "/group/del", params),
    add: (params) => Vue.axios.post(base + "/group/add", params),
    all: (params) => Vue.axios.post(base + "/group/all", params),
    move: (params) => Vue.axios.post(base + "/group/move", params),
}


attach.remoteConf = (params)=>Vue.axios.post(base + "/remote-conf", params)
attach.remoteConfEdit = (params)=>Vue.axios.post(base + "/remote-conf-edit", params)
attach.remoteTxCos = (params)=>Vue.axios.post(base + "/remote-tx-cos", params)
attach.remoteTxCosEdit = (params)=>Vue.axios.post(base + "/remote-tx-cos-edit", params)
attach.remoteAliOss = (params)=>Vue.axios.post(base + "/remote-ali-oss", params)
attach.remoteAliOssEdit = (params)=>Vue.axios.post(base + "/remote-ali-oss-edit", params)
attach.remoteQiniu = (params)=>Vue.axios.post(base + "/remote-qiniu", params)
attach.remoteQiniuEdit = (params)=>Vue.axios.post(base + "/remote-qiniu-edit", params)

attach.remoteAllLocalUp = (params)=>Vue.axios.post(base + "/remote-all-local-up", params)
attach.richTextReplace = (params)=>Vue.axios.post(base + "/rich-text-replace", params)

export default attach;