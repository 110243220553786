import Vue from "vue";
import config from "@/config";

const sys = {};
const base = config.api_base + "/shop/sys";


sys.info = (params)=>Vue.axios.post( base + "/info",params);
sys.infoEdit = (params)=>Vue.axios.post( base + "/info-edit",params);

sys.posterV2List = (params)=>Vue.axios.post( base + "/poster-v2-list",params);
sys.posterV2Edit = (params)=>Vue.axios.post( base + "/poster-v2-edit",params);
sys.posterV2One = (params)=>Vue.axios.post( base + "/poster-v2-one",params);
sys.posterV2Del = (params)=>Vue.axios.post( base + "/poster-v2-del",params);
sys.posterV2Clear = (params)=>Vue.axios.post( base + "/poster-v2-clear",params);

export default sys;